import PagesComponent from "../components/PagesComponent";
function Store() {
  return (
    <>
      <PagesComponent></PagesComponent>
    </>
  );
}

export default Store;
